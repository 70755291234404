import { useEffect, useState } from 'react';
import { openDB } from 'idb';


const useIDB = () => {

    const [idb, setIdb] = useState([]);

    useEffect(() => {
        async function fetchData() {
          try {
            const db = await openDB('myDatabase', 1);
    
            if (!db.objectStoreNames.contains('myObjectStore')) {
              console.error('Object store "myObjectStore" does not exist.');
              return;
            }
    
            const transaction = db.transaction('myObjectStore', 'readonly');
            const objectStore = await transaction.objectStore('myObjectStore').getAll();
            const cursor = await objectStore;
            console.log('adilist',cursor);
            if(cursor.length>=0){
              setIdb(cursor);
            }
            else{
              setIdb([]);
            }
            
          } catch (error) {
            console.error('Error:', error);
          }
        }
    
        fetchData();
      }, []);

      return idb;
    }

    export default useIDB;
