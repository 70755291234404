import "primeflex/primeflex.css";
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './components/header/header.js'; 
import PostAJob from './pages/PostAJob';
import Company from './pages/Company';
import { ScrollTop } from 'primereact/scrolltop';
import Success from './pages/Success';
import Admin from './components/Admin/Admin.js';
import MyDBData from './components/MyDB.js';
import Fulltime from './pages/Categories/Fulltime_jobs.js'
import Development from './pages/Categories/Development_jobs.js';
import NotFound from './components/NotFound.js';
import Navbar from './components/Navbar.js';

function App() {
  return (
    <div className="App">
      <MyDBData/>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Header/>} />
        <Route path='post-a-job' element={ <PostAJob/>}/>
        <Route path='companies' element={ <Company />}/>
        <Route strictly path='success' element= {<Success />} />
        <Route strictly path='adminadi' element= {<Admin/>} />
        <Route path='jobs/fulltime-jobs' element = {<Fulltime/>} />
        <Route path='jobs/development' element = {<Development />} />
        <Route path='*' element= {<NotFound/>} />
      </Routes> 
      <ScrollTop style={{backgroundColor: '#3B82F6', bottom: '75px'}} />
    </div >
  );
}

export default App;
