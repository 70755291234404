import { Dock } from 'primereact/dock';
import '../../components/SideNavBarSocialIcons/SideBarSocial.css'
import TwitterIcon from '../Icons/icons8-twitter.svg';
import FbIcon from '../Icons/icons8-facebook.svg';
import TelegramIcon from '../Icons/icons8-telegram.svg';
import LinkedinIcon from '../Icons/icons8-linkedin.svg';
import WhatsappIcon from '../Icons/icons8-whatsapp.svg';

export default function SideNavbarSocialIcons() {

    const items = [
        {
            label: 'Telegram',
            icon: () => <img src={TelegramIcon} alt="Telegram" width="75%" style={{cursor: 'pointer'}} />,
            url: 'https://t.me/cryptogaterjobs',
            target: '_blank',
        },
        { 
            label: 'Whatsapp',
            icon: () => <img src={WhatsappIcon} alt='Whatsapp' width="75%" style={{cursor: 'pointer'}} />,
            url: 'https://chat.whatsapp.com/Gi7j1doVap6KOW8sCBoulX',
            target: '_blank'
        },
        {
            label: 'Facebook',
            icon: () => <img src={FbIcon} alt="Facebook" width='75%' style={{cursor: 'pointer'}} />,
            url: 'https://facebook.com/cryptogater',
            target: '_blank',
        },
        {
            label: 'Twitter',
            icon: () => <img src={TwitterIcon} alt='Twitter' width='75%' style={{cursor: 'pointer'}} />,
            url: 'https://x.com/cryptogaterjobs',
            target: '_blank',
        },
        {
            label: 'LinkedIn',
            icon: () => <img src={LinkedinIcon} alt='LinkedIn' width='75%' style={{cursor: 'pointer'}} />,
            url: 'https://linkedin.com/cryptogater',
            target: '_blank',
        },
    ];

    return (
        <div className='dock-demo hidden md:block' >

           {/* <Menubar model={items} position='right'  /> */}
           <Dock model={items} position='right' onClick={items.map((item)=> item.url)} />
        </div>
    )
}        