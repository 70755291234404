import React, { useState } from 'react'
import useIDB from './useIDB'
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Tag } from 'primereact/tag';
//import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { TabView, TabPanel } from 'primereact/tabview';
import { Image } from 'primereact/image';
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import locationImage from '../assets/images/locationTag.svg'
import '../components/JobCardsAccordian.css';

const Development = () => {
    const posts = useIDB();
    const [search, setSearch] = useState('');

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };


    return (
        <div className='flex flex-column justify-content-center align-content-center align-items-center'>

            <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem 2rem' }}>
                <span className="p-input-icon-left" >
                    <i className="pi pi-search" />
                    <InputText placeholder="Search by Title/Company name" onChange={(e) => setSearch(e.target.value)} />
                </span>
            </div>

            <div className='p-2 w-full mb-7 md:w-10'>
                {
                    posts.filter((post) => {
                        const searchTerm = search.toLowerCase(); // Convert search term to lowercase
                        const jobTitle = post.jobTitle.toLowerCase(); // Convert jobTitle to lowercase
                        const companyName = post.companyName.toLowerCase(); // Convert companyName to lowercase
                        return searchTerm === '' ? true : jobTitle.includes(searchTerm) || companyName.includes(searchTerm)  // Use lowercase values for comparison
                    }).map((post) =>

                    (
                        // code for mapping each filtered post
                        (post.category.name === 'Development' && post.isActive === 'true') ?
                            <div>
                                <Accordion activeIndex={post.id}>
                                    <AccordionTab header={
                                        <>
                                            <div style={{ display: 'block', fontFamily: 'Inter' }} key={post.id}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ marginRight: '15px' }} className='hidden sm:block'>
                                                            <Image src={post.img} alt="Image" zoomSrc={post.img} width="fit-content" height="75px" preview />
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                            <div style={{ fontSize: '18px', marginBottom: '7px' }} key={post.jobTitle} className=''>
                                                                {post.jobTitle}
                                                            </div>
                                                            <div style={{ marginBottom: '7px' }} className='text-base'>
                                                                {post.companyName}
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }} className=''>
                                                                <div style={{ marginRight: '5px' }}>
                                                                    <Tag value={post.category.name} severity="info" className=''>
                                                                    </Tag>
                                                                </div>
                                                                <div style={{ marginRight: '5px' }} className='sm:text-sm'>
                                                                    <Tag value={post.jobType.name} icon="pi pi-briefcase" style={{ backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                                                </div>
                                                                <div style={{ marginRight: '5px' }} className='xs:text-sm'>
                                                                    <>
                                                                        {
                                                                            post.jobPosition === "100% Remote(Anywhere in the world)" ? <Tag value={post.jobPosition} icon="pi pi-globe" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                                        }
                                                                        {
                                                                            post.jobPosition === "100% Remote (Restricted to a specific geography/location)" ? <Tag value="100% Remote" icon="pi pi-wifi" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                                        }

                                                                        {
                                                                            post.jobPosition === "Remote Hybrid" ? <Tag value={post.jobPosition} icon="pi pi-slack" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                                        }
                                                                        {
                                                                            post.jobPosition === "In-Office" ? <Tag value={post.jobPosition} icon="pi pi-building" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                                        }
                                                                    </>
                                                                </div>
                                                                {
                                                                    post.jobLocation === "" ?
                                                                        (<div style={{ marginRight: '5px' }} hidden>
                                                                            <Tag value={post.jobLocation} icon={<img src={locationImage} alt=''/>} style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                                                        </div>) : (<div style={{ marginRight: '5px' }}>
                                                                            <Tag value={post.jobLocation} icon={<img src={locationImage} alt='' />} style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                                                        </div>)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='postedDate'>
                                                        {post.postedDate}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    >
                                        <ScrollPanel style={{ width: '100%', maxHeight: '500px', fontFamily: 'Inter' }} >
                                            <TabView>
                                                <TabPanel header="Job Role">
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: '120%', padding: '1rem', paddingRight: '1.5rem' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontFamily: 'Inter' }}>
                                                                <div style={{ marginLeft: '1rem', fontFamily: 'Inter', lineHeight: '25px' }}>
                                                                    <div>
                                                                        {post.postedDate === null ? null :
                                                                            <h4>Posted on  :  <span style={{ fontSize: '15px', color: '#6366F1' }}>{post.postedDate}</span></h4>
                                                                        }
                                                                    </div>
                                                                    <h4>Skills</h4>
                                                                    {post.chipArray.map((chip) => {
                                                                        return (
                                                                            <>
                                                                                <Tag value={chip} style={{ marginRight: '2px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }}>
                                                                                </Tag>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                                {/* //This timeStamp toDate is giving an error and blocking the page from rendering */}

                                                            </div>
                                                            <div className="m-1" style={{ padding: '1rem', paddingRight: '1.5rem', overflowX: 'hidden', fontFamily: 'Inter', lineHeight: '25px' }}>
                                                                {post.jobLocation === "" ? null :
                                                                    <div style={{ marginBottom: '35px' }}>
                                                                        <h4 style={{ marginBottom: '9px' }}>Location</h4>
                                                                        <Tag value={post.jobLocation} icon={<img src={locationImage} alt='' />} style={{ backgroundColor: 'white', color: '#6366F1', border: 'none', marginLeft: '-8px' }} />
                                                                    </div>
                                                                }
                                                                <h4>About the Job</h4>
                                                                <div>
                                                                    {/* <span>Is the Job remote?</span> */}
                                                                    {post.isRemote}
                                                                </div>
                                                                {/* <div>
                                <span>Skills</span>
                                {post.chipArray}
                              </div> */}
                                                                <div>
                                                                    {/* <span style={{ lineHeight: '1.5rem' }}>{post.jobDescription}</span> */}
                                                                    <div dangerouslySetInnerHTML={{ __html: post.jobDescription }} />
                                                                </div>
                                                                <div>
                                                                    <h4>Salary Compensation</h4>
                                                                    <span>{post.currency.code}</span><br />
                                                                    {post.minSalary}<span>-</span>{post.maxSalary}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: '1rem', marginTop: '1rem', width: '50%' }} className='hidden xl:block'>
                                                            <Card title={post.jobTitle} subTitle={post.companyName} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><Button label='Apply' icon="pi pi-external-link" onClick={() => openInNewTab(post.applyURL)} /></div>} className="" style={{ boxShadow: '0.5px 5px 10px #888888' }}>
                                                                <div>
                                                                    <Button label="View Website" icon="pi pi-external-link" iconPos="right" onClick={() => openInNewTab(post.companyWebsite)} style={{ width: 'auto', backgroundColor: '#3B82F6', color: 'white', borderRadius: '25px', borderColor: '#3B82F6' }} />
                                                                </div>
                                                                <br />
                                                                <div className="m-0" style={{ marginTop: '7px', display: 'flex', flexWrap: 'wrap' }} >
                                                                    <div style={{ margin: '2px' }}>
                                                                        <Tag value={post.category.name} icon="pi pi-microsoft" rounded />
                                                                    </div>
                                                                    <div style={{ margin: '2px' }}>
                                                                        <Tag value={post.jobType.name} icon="pi pi-briefcase" rounded />
                                                                    </div>
                                                                    <div style={{ margin: '2px' }}>
                                                                        {post.jobLocation === null ? 'USA' : <Tag value={post.jobLocation} icon={<img src={locationImage} alt='' />} rounded />}
                                                                    </div>
                                                                    <div style={{ margin: '2px' }}>
                                                                        {
                                                                            post.jobPosition === "100% Remote(Anywhere in the world)" ? <Tag value={post.jobPosition} icon="pi pi-globe" rounded /> : ''
                                                                        }
                                                                        {
                                                                            post.jobPosition === "100% Remote (Restricted to a specific geography/location)" ? <Tag value="100% Remote" icon="pi pi-wifi" rounded /> : ''
                                                                        }

                                                                        {
                                                                            post.jobPosition === "Remote Hybrid" ? <Tag value={post.jobPosition} icon="pi pi-slack" rounded /> : ''
                                                                        }
                                                                        {
                                                                            post.jobPosition === "In-Office" ? <Tag value={post.jobPosition} icon="pi pi-building" rounded /> : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <p>Tags</p>
                                                                <div style={{ marginTop: '9px', display: 'flex', flexWrap: 'wrap' }}>
                                                                    {post.chipArray.map((chip) => {
                                                                        return (
                                                                            <>
                                                                                <Tag value={chip} style={{ marginRight: '2px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }}>
                                                                                </Tag>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                {/* About the company panel */}
                                                <TabPanel header="About the Company">
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ padding: '1rem', paddingRight: '1.5rem', overflowX: 'hidden', width: '120%', fontFamily: 'Inter', lineHeight: '25px' }}>
                                                            <span>
                                                                <h2>{post.companyName}</h2>
                                                                <h4>{post.companyTagline}</h4>
                                                            </span>
                                                            <div>
                                                                <div dangerouslySetInnerHTML={{ __html: post.companyDescription }} />
                                                            </div>
                                                        </div>
                                                        <div className='hidden xl:block' style={{ padding: '1rem', marginTop: '1rem', width: '50%' }}>
                                                            {/* //This timeStamp toDate is giving an error and blocking the page from rendering */}
                                                            <div className='postedOn' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                {/* <h5>{post.timeStamp.toDate().toString().slice(3, 10)}</h5> */}
                                                            </div>
                                                            <Card title={post.jobTitle} subTitle={post.companyName} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><Button label='Apply' icon="pi pi-external-link" onClick={() => openInNewTab(post.applyURL)} /></div>} className="" style={{ boxShadow: '0.5px 5px 10px #888888' }}>
                                                                <div>
                                                                    <Button label="View Website" icon="pi pi-external-link" iconPos="right" onClick={() => openInNewTab(post.companyWebsite)} style={{ width: 'auto', backgroundColor: '#3B82F6', color: 'white', borderRadius: '25px', borderColor: '#3B82F6' }} />
                                                                </div>
                                                                <br />
                                                                <div className="m-0" style={{ marginTop: '7px', display: 'flex', flexWrap: 'wrap' }} >
                                                                    <div style={{ margin: '2px' }}>
                                                                        <Tag value={post.category.name} icon="pi pi-microsoft" rounded />
                                                                    </div>
                                                                    <div style={{ margin: '2px' }}>
                                                                        <Tag value={post.jobType.name} icon="pi pi-briefcase" rounded />
                                                                    </div>
                                                                    <div style={{ margin: '2px' }}>
                                                                        {post.jobLocation === null ? 'USA' : <Tag value={post.jobLocation} icon={<img src={locationImage} alt='' />} rounded />}
                                                                    </div>
                                                                    <div style={{ margin: '2px' }}>
                                                                        {
                                                                            post.jobPosition === "100% Remote(Anywhere in the world)" ? <Tag value={post.jobPosition} icon="pi pi-globe" rounded /> : ''
                                                                        }
                                                                        {
                                                                            post.jobPosition === "100% Remote (Restricted to a specific geography/location)" ? <Tag value="100% Remote" icon="pi pi-wifi" rounded /> : ''
                                                                        }

                                                                        {
                                                                            post.jobPosition === "Remote Hybrid" ? <Tag value={post.jobPosition} icon="pi pi-slack" rounded /> : ''
                                                                        }
                                                                        {
                                                                            post.jobPosition === "In-Office" ? <Tag value={post.jobPosition} icon="pi pi-building" rounded /> : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <p>Tags</p>
                                                                <div style={{ marginTop: '9px', display: 'flex', flexWrap: 'wrap' }}>
                                                                    {post.chipArray.map((chip) => {
                                                                        return (
                                                                            <>
                                                                                <Tag value={chip} style={{ marginRight: '2px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }}>
                                                                                </Tag>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            </TabView>
                                        </ScrollPanel>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button className='applyBtn' label='Apply' icon="pi pi-external-link" onClick={() => openInNewTab(post.applyURL)} style={{ marginTop: '15px', padding: '1% 10% 1% 10%' }} />
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div> : null
                    ))
                }

            </div>
        </div>

    )
}

export default Development
