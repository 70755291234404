import { Tooltip } from "primereact/tooltip";
import { Badge } from "primereact/badge";

export default function TooltipComponent({ description }) {
  return (
    <>
      <Tooltip target=".custom-target-icon" />
      <i
        className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
        data-pr-tooltip={description}
        // data-pr-position="right"
        // data-pr-at="right+5 top"
        // data-pr-my="left center-2"
        style={{ fontSize: "1rem", cursor: "pointer" }}
      >
        <Badge severity="danger"></Badge>
      </i>
    </>
  );
}
