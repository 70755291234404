import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db, storage } from "../firebase";
import { Tag } from "primereact/tag";
//import { Skeleton } from 'primereact/skeleton';
import { Button } from "primereact/button";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import locationImage from "../assets/images/locationTag.svg";
import "../components/JobCardsAccordian.css";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { format, differenceInHours, differenceInDays } from 'date-fns';


function JobCardsAccordionTest() {
  const [posts, setPosts] = useState([]);
  const [imageList, setImageList] = useState([]);
  const imageListRef = ref(storage, "images/");

  const postsCollectionRef = collection(db, "posts");

  const [activeIndex, setActiveIndex] = useState(null); // Initialize activeIndex state


  //UPDATE THE URL OF PAGE BY THE JOB POST SELECTED
  // const handleClick = (index, jobTitle, companyName) => {
  //   // Update activeIndex state
  //   setActiveIndex(index);

  //   // Update URL using pushState
  //   const formattedJobTitle = jobTitle.toLowerCase().replace(/\s+/g, '-');
  //   const formattedCompanyName = companyName.toLowerCase().replace(/\s+/g, '-');
  //   const newUrl = `/${formattedJobTitle}-${formattedCompanyName}`;

  //   window.history.pushState(null, null, newUrl);
  // };

  const options = ["", ""];
  const [value, setValue] = useState(options[0]);

  useEffect(() => {
    //Get job posts from firestore
    const getPosts = async () => {
      const data = await getDocs(postsCollectionRef);
      setPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getPosts();
  }, []);

  useEffect(() => {
    //get image logo from storage
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const [search, setSearch] = useState("");

  function getPostedOn(timeStampUTC) {
    const now = new Date();
    const jobPostedDate = new Date(timeStampUTC);
  
    // Ensure the timestamp is valid
    if (isNaN(jobPostedDate)) {
      return "Invalid date";
    }
  
    const hoursDifference = differenceInHours(now, jobPostedDate);
    const daysDifference = differenceInDays(now, jobPostedDate);
  
    if (hoursDifference < 24) {
      // Handle "X hours ago"
      if (hoursDifference === 0) {
        return "Just now";
      }
      return `${hoursDifference} hours ago`;
    } else if (daysDifference === 0) {
      // Handle "Today"
      return "Today";
    } else if (daysDifference === 1) {
      // Handle "Yesterday"
      return "Yesterday";
    } else {
      // For more than 2 days, show the date in the desired format
      return format(jobPostedDate, 'do MMM'); // e.g., "24th Sep", "11th June"
    }
  }

  //For Dialog popup

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState('');
  const [selectedPost, setSelectedPost] = useState(null);

  const handleCardClick = (post) => {
    setSelectedPost(post);
    setVisible(true);
  };

  const handleCloseDialog = () => {
    setVisible(false);
    setSelectedPost(null);
  };

  const show = (position) => {
    setPosition(position);
    setVisible(true);
  };

  const responsiveShow = () => {
    // Use window.matchMedia to detect screen size
    if (window.matchMedia('(max-width: 768px)').matches) {
      show('bottom');  // For smaller screens
    } else {
      show('center');  // For larger screens
    }
  };

  const footerContent = (
    <div className="block md:hidden">
        <Button id="dialogFooterButton"  label='Apply' icon="pi pi-external-link" className="w-full" onClick={() => openInNewTab(selectedPost.applyURL)}  />
    </div>
);

  return (
    <>
      <div className="flex flex-column justify-content-center align-content-center align-items-center">
        {/* The card skeleton that needs to be rendered on load
        
        <div className="card">
        <div className="border-round border-1 surface-border p-4 surface-card">
          <div className="flex justify-content-between align-items-center">
            <div className="flex">
              <Skeleton width="105px" height="105px" className="mr-3"></Skeleton>
              <div>
                <Skeleton width="12rem" height="2.5rem" className="mb-3"></Skeleton>
                <Skeleton width="8rem" height="2rem" className="mb-3"></Skeleton>
                <Skeleton width="30rem" height="1rem"></Skeleton>
              </div>
            </div>
            <Skeleton width="4rem" height="2rem"></Skeleton>
          </div>
        </div>
      </div> */}

        <div
          style={{
            display: "inline-flex",
            justifyContent: "center",
            padding: "2rem 0",
          }}
          className="w-full"
        >
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search by Skill/Title/Company name"
              onChange={(e) => setSearch(e.target.value)}
              className="w-full"
            />
          </span>
          {/* <SelectButton value={value} onChange={(e) => setValue(e.value)} options={options} /> */}
        </div>

        <div className="p-2 w-full mb-7 md:w-10">
          {posts
            .filter((post) => {
              const searchTerm = search.toLowerCase(); // Convert search term to lowercase
              const jobTitle = post.jobTitle.toLowerCase(); // Convert jobTitle to lowercase
              const companyName = post.companyName.toLowerCase(); // Convert companyName to lowercase
              const skills = post.chipArray.map((chip) => chip.toLowerCase()); // Convert each skill to lowercase
              return searchTerm === ""
                ? true
                : jobTitle.includes(searchTerm) ||
                    companyName.includes(searchTerm) ||
                    skills.some((skill) => skill.includes(searchTerm)); // Use lowercase values for comparisons
            })
            .map((post) =>
              // code for mapping each filtered post
              post.isActive === "true" ? (
                <>
                 {/* The dialog version */}

                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "8rem",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                        cursor: "pointer",
                        marginBottom: '2px',
                        backgroundColor: 'white'
                      }}
                      onClick={() => {
                        handleCardClick(post); 
                        responsiveShow();
                       // handleClick(post.id, post.jobTitle, post.companyName)
                      }}
                    >
                      <div
                        style={{ display: "block", fontFamily: "Inter" }}
                        key={post.id}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "block",
                                textAlign: "center",
                                alignContent: "center",
                                width: "6rem",
                                height: "6rem",
                                marginLeft: "1.2rem",
                                marginRight: "15px",
                              }}
                              className="hidden sm:block"
                            >
                              {post.img ? (
                                <Image
                                  src={post.img}
                                  alt="Image"
                                  zoomSrc={post.img}
                                  width="100%"
                                  height="auto"
                                  preview
                                />
                              ) : (
                                <h4>No image</h4>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "7px",
                                  color: "#0f172a",
                                  fontWeight: "bold",
                                }}
                                key={post.jobTitle}
                                className="ml-2"
                              >
                                {post.jobTitle}
                              </div>
                              <div
                                style={{
                                  marginBottom: "12px",
                                  color: "darkslategray",
                                  fontWeight: "700",
                                }}
                                className="text-base ml-2"
                              >
                                {post.companyName}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="ml-2"
                              >
                                <div style={{ marginRight: "5px" }}>
                                  <Tag
                                    value={post.category.name}
                                    severity="info"
                                    className=""
                                  ></Tag>
                                </div>
                                <div
                                  style={{ marginRight: "5px" }}
                                >
                                  <Tag
                                    value={post.jobType.name}
                                    icon="pi pi-briefcase"
                                    style={{
                                      backgroundColor: "white",
                                      color: "#6366F1",
                                      border: "0.5px solid #6366F1",
                                    }}
                                  />
                                </div>
                                <div
                                  style={{ marginRight: "5px" }}
                                  className="hidden md:block"
                                >
                                  <>
                                    {post.jobPosition ===
                                    "100% Remote(Worldwide)" ? (
                                      <Tag
                                        value={post.jobPosition}

                                        icon="pi pi-globe"
                                        style={{
                                          fontSize: "12px",
                                          backgroundColor: "white",
                                          color: "#6366F1",
                                          border: "0.5px solid #6366F1",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {post.jobPosition ===
                                    "100% Remote(Location-restricted)" ? (
                                      <Tag
                                        value={post.jobPosition}
                                        icon="pi pi-wifi"
                                        style={{
                                          fontSize: "12px",
                                          backgroundColor: "white",
                                          color: "#6366F1",
                                          border: "0.5px solid #6366F1",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}

                                    {post.jobPosition === "Remote Hybrid" ? (
                                      <Tag
                                        value={post.jobPosition}
                                        icon="pi pi-slack"
                                        style={{
                                          fontSize: "12px",
                                          backgroundColor: "white",
                                          color: "#6366F1",
                                          border: "0.5px solid #6366F1",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {post.jobPosition === "In-Office" ? (
                                      <Tag
                                        value="On-site"
                                        icon="pi pi-building"
                                        style={{
                                          fontSize: "12px",
                                          backgroundColor: "white",
                                          color: "#6366F1",
                                          border: "0.5px solid #6366F1",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                </div>
                                {post.jobLocation === "" ? (
                                  <div style={{ marginRight: "5px" }} hidden>
                                    <Tag
                                      value={post.jobLocation}
                                      icon={<img src={locationImage} alt="" />}
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor: "white",
                                        color: "#6366F1",
                                        border: "0.5px solid #6366F1",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div style={{ marginRight: "5px" }} className="hidden md:block" >
                                    <Tag
                                      value={post.jobLocation}
                                      icon={<img src={locationImage} alt="" />}
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor: "white",
                                        color: "#6366F1",
                                        border: "0.5px solid #6366F1",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                             </div>
                            </div>
                           </div>
                          </div>
                         <div className="hidden xl:block" style={{marginRight: '2rem'}}>{getPostedOn(post.timeStampUTC) === 'Invalid date'? null: getPostedOn(post.timeStampUTC)}</div>
                       </div>
                    {visible && selectedPost && (
                      <Dialog
                        visible={visible}
                        //style={{ width: "85vw", height: "90vh" }}
                        id="dialogJob"
                        //className="JobDialog"
                        className="w-full h-full m-0 md:w-10 md:h-[90vh]"
                        position= {position}
                        onHide={handleCloseDialog}
                        footer={footerContent}
                        footerStyle={{padding:'0'}}
                        header= {selectedPost && (
                        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%',  alignItems: 'center'}}>
                         <div style={{display: 'flex'}}>
                           <div style={{display: "block", alignContent: "center",marginRight: "1.5rem"}}>
                                {selectedPost.img ? (
                                      <Image
                                        src={selectedPost.img}
                                        alt="Image"
                                        zoomSrc={selectedPost.img}
                                        width="50rem"
                                        height="100%"
                                        preview
                                      />
                                    ) : (
                                      <h6>No image</h6>
                                    )}
                             </div>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                             <span style={{ fontWeight: 'bold', fontFamily: 'Inter'}} className="text-xl md:text-xl">{selectedPost.jobTitle}</span>
                                  <p className="text-sm font-bold" style={{fontFamily: 'Inter'}} >
                                    {selectedPost.companyName}
                                  </p>
                            </div>
                          </div>

                          {/* Call to action buttons */}
                         
                          <div style={{display: 'flex',flexDirection: 'column',marginRight: '2rem'}}>
                              <Button className="hidden md:block" label="View Website" link icon="pi pi-link" iconPos="left" onClick={() => openInNewTab(selectedPost.companyWebsite)} style={{ color: '#3B82F6', fontSize: '14px', backgroundColor: 'white', borderColor: 'white'}} />
                              <Button className='hidden md:block' label='Apply' icon="pi pi-external-link" iconPos="left" onClick={() => openInNewTab(selectedPost.applyURL)}  />
                          </div>
                       </div> 
                        )}
                       headerStyle={{padding: '1rem'}}
                      >

                        {selectedPost && (
                        <>  
                         <section className="flex-column md:flex md:flex-row">
                          <div key={selectedPost.id} style={{flex: '30%', backgroundColor: 'white'}} className="px-2 md:pr-3 md:pt-3 md:border-right-1 border-purple-100">
                            <div style={{display: 'flex', justifyContent: 'space-between'}} className="flex-row md:flex md:flex-column xl:flex-row">
                              <div>
                                <p style={{fontSize: '14px'}}>Job Type</p>
                                <p style={{fontSize: '16px', fontWeight: 'bold'}}>{selectedPost.jobType.name}</p>
                              </div>
                               <div>
                                <p style={{fontSize: '14px'}}>Category</p>
                                <p style={{fontSize: '16px', fontWeight: 'bold'}}>{selectedPost.category.name}</p>
                               </div>
                            </div>
                            <Divider/>
                            <div style={{display: 'flex', justifyContent: 'space-between'}} className="flex flex-column sm:flex-row md:flex-column">
                              <div>
                                <p style={{fontSize: '14px'}}>Work Arrangement</p>
                                <p style={{fontSize: '16px', fontWeight: 'bold'}}>{selectedPost.jobPosition}</p>
                              </div>
                              <Divider className="block sm:hidden md:block"/>
                              <div>
                                <p style={{fontSize: '14px'}}>Location</p>
                                <p style={{fontSize: '16px', fontWeight: 'bold'}}>{selectedPost.jobLocation === ''?<span>-</span> : selectedPost.jobLocation}</p>
                              </div>
                            </div>

                            <Divider/>
                    
                            <div style={{display: 'flex', justifyContent: 'space-between'}} className="md:flex-column xl:flex-row">
                              <div>
                                <p style={{fontSize: '14px'}}>Work Experience</p>
                                <span style={{fontSize: '16px', fontWeight: 'bold'}}>{selectedPost.minExperience}</span> - <span style={{fontSize: '16px', fontWeight: 'bold'}}>{selectedPost.maxExperience} yrs</span>
                              </div>
                              <Divider className="hidden md:block xl:hidden"/>
                              <div>
                                <p style={{fontSize: '14px'}}>Salary ({selectedPost.currency.name})</p>
                                <p style={{fontSize: '16px', fontWeight: 'bold'}}>{selectedPost.currency.code}{selectedPost.minSalary < 1000 ? selectedPost.minSalary: (selectedPost.minSalary/1000).toFixed(selectedPost.minSalary % 1000 !== 0? 1:0) + 'k'}<span> - </span>{selectedPost.currency.code}{selectedPost.maxSalary < 1000 ? selectedPost.maxSalary: (selectedPost.maxSalary/1000).toFixed(selectedPost.maxSalary % 1000 !== 0? 1:0) + 'k'} </p>
                              </div>
                            </div>

                            <Divider/>

                            <div>
                              <p style={{fontSize: '14px'}}>Required Skills</p>
                              {selectedPost.chipArray.map((chip) => {
                                      return (
                                        <>
                                          <Tag value={chip} style={{ marginRight: '2px',marginTop: '2px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }}>
                                          </Tag>
                                        </>
                                      )
                                    })}
                            </div>

                            <Divider/>
                            <p style={{fontSize: '14px'}} className="hidden md:block">Posted On : {selectedPost.postedDate}</p>
                          </div>

                          <div key={selectedPost.id} style={{flex: '70%', backgroundColor: 'white'}} className="px-2 md:px-3 md:pt-2 xl:pt-2">
                              <div>
                                 <h3>Job Description</h3>
                                 <div dangerouslySetInnerHTML={{ __html: selectedPost.jobDescription }} />
                              </div>
                              <br/>
                              <div>
                                <h3>About Company</h3>
                                <div dangerouslySetInnerHTML={{ __html: selectedPost.companyDescription }} />
                              </div>
                              <br/>
                              <div>
                                    <h3>Benefits</h3>
                                    <span>{selectedPost.benefitsOffered == null? 'N/A' : 
                                    selectedPost.benefitsOffered.map((benefits) => {return(
                                    <>
                                     <ul><li>{benefits.name}</li></ul>
                                    </>)})} </span>
                                  </div>
                           </div>
                       </section>
                     </>
                      )}
                    </Dialog>
                    )}
                  </div>
                </>
              ) : null
            )}
        </div>
      </div>
    </>
  );
}

export default JobCardsAccordionTest;
