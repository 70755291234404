import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { openDB } from 'idb';

function MyDBData() {
    const [posts, setPosts] = useState([]);
    const postsCollectionRef = collection(db, "posts");

    useEffect(() => {
        async function fetchData() {
            try {
                const db = await openDB('myDatabase', 1, {
                    upgrade(db) {
                        const store = db.createObjectStore('myObjectStore', { keyPath: 'id' });
                        // You can add indexes or customize further if needed
                    },
                });

                const data = await getDocs(postsCollectionRef);
                const postsFromFirebase = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

                await Promise.all([
                    setPosts(postsFromFirebase), // Update state
                    savePostsToIndexedDB(db, postsFromFirebase), // Save to IndexedDB
                ]);
            } catch (error) {
                console.error('Error:', error);
            }
        }

        fetchData();
    }, []);

    const savePostsToIndexedDB = async (db, posts) => {
        if (!db) return;

        const transaction = db.transaction(['myObjectStore'], 'readwrite');
        const objectStore = transaction.objectStore('myObjectStore');

        await Promise.all(posts.map(async (post) => {
            try {
                await objectStore.put(post);
            } catch (error) {
                console.error('Error saving post to IndexedDB:', error);
            }
        }));
    };
    
}

export default MyDBData;
