import React from 'react'

function Features() {
    return (
        <div className="surface-0 text-center p-5 mt-7">
            <div className="mb-3 font-bold text-3xl">
                <span className="text-900">Why post with us.</span>
                <span className="text-blue-600"> Here's why?</span>
            </div>
            <div className="text-700 mb-6">We want you to stand out and reach as many candidates as possible. Let us help you find the best talent from our qualified audience</div>
            <div className="grid">
                <div className="col-12 md:col-4 mb-4 px-5">
                    <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                        <i className="pi pi-mobile text-4xl text-blue-500"></i>
                    </span>
                    <div className="text-900 text-xl mb-3 font-medium">Responsive Interface</div>
                    <span className="text-700 line-height-3">Adaptive Design that seamlessly fits any device size and resolution.</span>
                </div>
                <div className="col-12 md:col-4 mb-4 px-5">
                    <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                        <i className="pi pi-sign-in text-4xl text-blue-500"></i>
                    </span>
                    <div className="text-900 text-xl mb-3 font-medium">No sign-in required</div>
                    <span className="text-700 line-height-3">Create your job post without the hassle of signing in.</span>
                </div>
                <div className="col-12 md:col-4 mb-4 px-5">
                    <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                        <i className="pi pi-check-circle text-4xl text-blue-500"></i>
                    </span>
                    <div className="text-900 text-xl mb-3 font-medium">Verified Posts</div>
                    <span className="text-700 line-height-3">Legitimate job posts that are verified </span>
                </div>
                <div className="col-12 md:col-4 mb-4 px-5">
                    <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                        <i className="pi pi-globe text-4xl text-blue-500"></i>
                    </span>
                    <div className="text-900 text-xl mb-3 font-medium">Job alerts</div>
                    <span className="text-700 line-height-3">User will receive automatic job alerts tailored to them.</span>
                </div>
                <div className="col-12 md:col-4 mb-4 px-5">
                    <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                        <i className="pi pi-telegram text-4xl text-blue-500"></i>
                    </span>
                    <div className="text-900 text-xl mb-3 font-medium">Amplified Social Media Reach</div>
                    <span className="text-700 line-height-3">We share your job post across our social platforms, connecting you with our dedicated followers.</span>
                </div>
                <div className="col-12 md:col-4 md:mb-4 mb-0 px-3">
                    <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                        <i className="pi pi-shield text-4xl text-blue-500"></i>
                    </span>
                    <div className="text-900 text-xl mb-3 font-medium">Free Job Posting</div>
                    <span className="text-700 line-height-3">Post your job completely free of cost.</span>
                </div>
            </div>
        </div>
   )
}

export default Features