import React, { useState } from 'react';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Image } from 'primereact/image';
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import locationImage from '../../assets/images/locationTag.svg'
import useIDB from '../useIDB';


const Admin = () => {

    const posts = useIDB();

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
    };

    const [search, setSearch] = useState('');
    const [visible, setVisible] = useState(false);

    const tagFilters = (type, value) => {
        openInNewTab(value)
    }

    const [selectedPost, setSelectedPost] = useState(null);

    const handleCardClick = (post) => {
        setSelectedPost(post);
        setVisible(true);
    };

    const handleCloseDialog = () => {
        setVisible(false);
        setSelectedPost(null);
    };

    return (
        <div style={{padding: '1rem 1rem'}}>
            <h2>ADMIN</h2>
            <h3>ACTIVE & PENDING POSTS</h3><p>Contains all the jobs. As an admin check and verify the new jobs that are posted</p>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '3rem 3rem' }}>
                <span className="p-input-icon-left" >
                    <i className="pi pi-search" />
                    <InputText placeholder="ACTIVE / PENDING" onChange={(e) => setSearch(e.target.value)} />
                </span>
            </div>

            <div className='p-2 w-full mb-7 md:w-10'>
                {

                    posts.filter((post) => {
                        const searchTerm = search.toLowerCase(); // Convert search term to lowercase
                        const jobTitle = post.jobTitle.toLowerCase(); // Convert jobTitle to lowercase
                        const companyName = post.companyName.toLowerCase(); // Convert companyName to lowercase
                        const postStatus = post.isActive.toLowerCase() === 'true' ? 'active' : 'pending'
                        return searchTerm === '' ? true : jobTitle.includes(searchTerm) || companyName.includes(searchTerm) || postStatus.includes(searchTerm)  // Use lowercase values for comparison
                    }).map((post) =>

                    (
                        // code for mapping each filtered post
                        <div>
                            <Card key={post.id} title={post.jobTitle} subTitle={post.id} header={<Image src={post.img} alt="Image" zoomSrc={post.img} width="fit-content" height="75px" />} className="flex md:w-100vw" onClick={() => handleCardClick(post)} style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', width: '85vw', height: 'auto' }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: '5px' }}>
                                                <Tag value={post.category.name} onClick={() => tagFilters(post.category.code, post.category.name)} severity="info" className=''>
                                                </Tag>
                                            </div>
                                            <div style={{ marginRight: '5px' }} className='sm:text-sm'>
                                                <Tag value={post.jobType.name} icon="pi pi-briefcase" style={{ backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                            </div>
                                            <div style={{ marginRight: '5px' }} className='xs:text-sm'>
                                                <>
                                                    {
                                                        post.jobPosition === "100% Remote(Anywhere in the world)" ? <Tag value={post.jobPosition} icon="pi pi-globe" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                    }
                                                    {
                                                        post.jobPosition === "100% Remote (Restricted to a specific geography/location)" ? <Tag value="100% Remote" icon="pi pi-wifi" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                    }

                                                    {
                                                        post.jobPosition === "Remote Hybrid" ? <Tag value={post.jobPosition} icon="pi pi-slack" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                    }
                                                    {
                                                        post.jobPosition === "In-Office" ? <Tag value={post.jobPosition} icon="pi pi-building" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                    }
                                                </>
                                            </div>
                                            {
                                                post.jobLocation === "" ?
                                                    (<div style={{ marginRight: '5px' }} hidden>
                                                        <Tag value={post.jobLocation} icon={<img src={locationImage} alt='' />} style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                                    </div>) : (<div style={{ marginRight: '5px' }}>
                                                        <Tag value={post.jobLocation} icon={<img src={locationImage} alt='' />} style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                                    </div>)
                                            }
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    <div className='postedDate'>
                                        <div>
                                            {post.isActive === 'true' ? <h2 style={{ color: 'green' }}>ACTIVE</h2> : <h2 style={{ color: 'red' }}>PENDING</h2>}
                                        </div>
                                        <div>
                                            {post.postedDate}
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            {visible && selectedPost && (
                                <Dialog header={selectedPost &&
                                    <div key={selectedPost.id} style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', borderColor: 'white' }}>
                                        <div className='dialogTags' style={{ display: 'flex' }}>
                                            <div style={{display: 'block',width: "fit-content" ,height: '75px' }}>
                                                <Image src={selectedPost.img} alt="Image" zoomSrc={selectedPost.img} width="fit-content" height="25px" preview />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <h6 style={{marginTop: '0'}}>
                                                    {selectedPost.jobTitle} 
                                                   <p>{selectedPost.companyName}</p> 
                                                </h6>
                                                <div style={{ display: 'none', alignItems: 'center' }} className=''>
                                                    <div style={{ marginRight: '5px' }} className='sm:text-sm'>
                                                        <Tag value={selectedPost.jobType.name} icon="pi pi-briefcase" style={{ backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                                    </div>
                                                    <div style={{ marginRight: '5px' }} className='xs:text-sm'>
                                                        <>
                                                            {
                                                                selectedPost.jobPosition === "100% Remote(Anywhere in the world)" ? <Tag value={selectedPost.jobPosition} icon="pi pi-globe" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                            }
                                                            {
                                                                selectedPost.jobPosition === "100% Remote (Restricted to a specific geography/location)" ? <Tag value="100% Remote" icon="pi pi-wifi" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                            }

                                                            {
                                                                selectedPost.jobPosition === "Remote Hybrid" ? <Tag value={selectedPost.jobPosition} icon="pi pi-slack" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                            }
                                                            {
                                                                selectedPost.jobPosition === "In-Office" ? <Tag value={selectedPost.jobPosition} icon="pi pi-building" style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} /> : ''
                                                            }
                                                        </>
                                                    </div>
                                                    {
                                                        selectedPost.jobLocation === "" ?
                                                            (<div style={{ marginRight: '5px' }} hidden>
                                                                <Tag value={selectedPost.jobLocation} icon={<img src={locationImage} alt='' />} style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                                            </div>) : (<div style={{ marginRight: '5px' }}>
                                                                <Tag value={selectedPost.jobLocation} icon={<img src={locationImage} alt='' />} style={{ fontSize: '12px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }} />
                                                            </div>)
                                                    }
                                                </div>
                                            </div>
                                            <div className='postedDate'>
                                                <div>
                                                    {selectedPost.isActive === 'true' ? <h2 style={{ color: 'green' }}>ACTIVE</h2> : <h2 style={{ color: 'red' }}>PENDING</h2>}
                                                </div>
                                                <div>
                                                    {selectedPost.postedDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } visible={visible} style={{ width: '100vw', height: '100vh' }} onHide={handleCloseDialog}>
                                    <TabView style={{ position: 'fixed', zIndex: '100' }}>
                                        <TabPanel leftIcon='pi pi-home' header="Job Role">
                                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '120%', padding: '1rem', paddingRight: '1.5rem' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontFamily: 'Inter' }}>
                                                        <div style={{ marginLeft: '1rem', fontFamily: 'Inter', lineHeight: '25px', marginTop: '3rem'}}>
                                                            <div>
                                                                {selectedPost.postedDate === null ? null :
                                                                    <h4>Posted on  :  <span style={{ fontSize: '15px', color: '#6366F1' }}>{selectedPost.postedDate}</span></h4>
                                                                }
                                                            </div>
                                                            <h4>Skills</h4>
                                                            {selectedPost.chipArray.map((chip) => {
                                                                return (
                                                                    <>
                                                                        <Tag value={chip} style={{ marginRight: '2px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }}>
                                                                        </Tag>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                        {/* //This timeStamp toDate is giving an error and blocking the page from rendering */}

                                                    </div>
                                                    <div className="m-1" style={{ padding: '1rem', paddingRight: '1.5rem', overflowX: 'hidden', fontFamily: 'Inter', lineHeight: '25px' }}>
                                                        {selectedPost.jobLocation === "" ? null :
                                                            <div style={{ marginBottom: '35px' }}>
                                                                <h4 style={{ marginBottom: '9px' }}>Location</h4>
                                                                <Tag value={selectedPost.jobLocation} icon={<img src={locationImage} alt=''/>} style={{ backgroundColor: 'white', color: '#6366F1', border: 'none', marginLeft: '-8px' }} />
                                                            </div>
                                                        }
                                                        <h4>About the Job</h4>
                                                        <div>
                                                            {/* <span>Is the Job remote?</span> */}
                                                            {selectedPost.isRemote}
                                                        </div>
                                                        <div>
                                                            <div dangerouslySetInnerHTML={{ __html: selectedPost.jobDescription }} />
                                                        </div>
                                                        <div>
                                                            <h4>Salary Compensation</h4>
                                                            <span>{selectedPost.currency.code}</span><br />
                                                            {selectedPost.minSalary}<span>-</span>{selectedPost.maxSalary}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ padding: '1rem', marginTop: '1rem', width: '50%' }} className='hidden xl:block'>
                                                    <Card title={selectedPost.jobTitle} subTitle={selectedPost.companyName} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><Button label='Apply' icon="pi pi-external-link" onClick={() => openInNewTab(selectedPost.applyURL)} /></div>} className="" style={{ boxShadow: '0.5px 5px 10px #888888' }}>
                                                        <div>
                                                            <Button label="View Website" icon="pi pi-external-link" iconPos="right" onClick={() => openInNewTab(selectedPost.companyWebsite)} style={{ width: 'auto', backgroundColor: '#3B82F6', color: 'white', borderRadius: '25px', borderColor: '#3B82F6' }} />
                                                        </div>
                                                        <br />
                                                        <div className="m-0" style={{ marginTop: '7px', display: 'flex', flexWrap: 'wrap' }} >
                                                            <div style={{ margin: '2px' }}>
                                                                <Tag value={selectedPost.category.name} icon="pi pi-microsoft" rounded />
                                                            </div>
                                                            <div style={{ margin: '2px' }}>
                                                                <Tag value={selectedPost.jobType.name} icon="pi pi-briefcase" rounded />
                                                            </div>
                                                            <div style={{ margin: '2px' }}>
                                                                {selectedPost.jobLocation === null ? 'USA' : <Tag value={selectedPost.jobLocation} icon={<img src={locationImage} alt='' />} rounded />}
                                                            </div>
                                                            <div style={{ margin: '2px' }}>
                                                                {
                                                                    selectedPost.jobPosition === "100% Remote(Anywhere in the world)" ? <Tag value={post.jobPosition} icon="pi pi-globe" rounded /> : ''
                                                                }
                                                                {
                                                                    selectedPost.jobPosition === "100% Remote (Restricted to a specific geography/location)" ? <Tag value="100% Remote" icon="pi pi-wifi" rounded /> : ''
                                                                }

                                                                {
                                                                    selectedPost.jobPosition === "Remote Hybrid" ? <Tag value={selectedPost.jobPosition} icon="pi pi-slack" rounded /> : ''
                                                                }
                                                                {
                                                                    selectedPost.jobPosition === "In-Office" ? <Tag value={selectedPost.jobPosition} icon="pi pi-building" rounded /> : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <p>Tags</p>
                                                        <div style={{ marginTop: '9px', display: 'flex', flexWrap: 'wrap' }}>
                                                            {selectedPost.chipArray.map((chip) => {
                                                                return (
                                                                    <>
                                                                        <Tag value={chip} style={{ marginRight: '2px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }}>
                                                                        </Tag>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* About the company panel */}
                                        <TabPanel header="About the Company">
                                            <div style={{ display: 'flex'}}>
                                                <div style={{ padding: '1rem', paddingRight: '1.5rem', overflowX: 'hidden', width: '120%', fontFamily: 'Inter', lineHeight: '25px', marginTop: '3rem' }}>
                                                    <span>
                                                        <h2>{selectedPost.companyName}</h2>
                                                        <h4>{selectedPost.companyTagline}</h4>
                                                    </span>
                                                    <div>
                                                        <div dangerouslySetInnerHTML={{ __html: selectedPost.companyDescription }} />
                                                    </div>
                                                </div>
                                                <div className='hidden xl:block' style={{ padding: '1rem', marginTop: '1rem', width: '50%' }}>
                                                    {/* //This timeStamp toDate is giving an error and blocking the page from rendering */}
                                                    <div className='postedOn' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        {/* <h5>{post.timeStamp.toDate().toString().slice(3, 10)}</h5> */}
                                                    </div>
                                                    <Card title={selectedPost.jobTitle} subTitle={selectedPost.companyName} footer={<div style={{ display: 'flex', justifyContent: 'center' }}><Button label='Apply' icon="pi pi-external-link" onClick={() => openInNewTab(post.applyURL)} /></div>} className="" style={{ boxShadow: '0.5px 5px 10px #888888' }}>
                                                        <div>
                                                            <Button label="View Website" icon="pi pi-external-link" iconPos="right" onClick={() => openInNewTab(selectedPost.companyWebsite)} style={{ width: 'auto', backgroundColor: '#3B82F6', color: 'white', borderRadius: '25px', borderColor: '#3B82F6' }} />
                                                        </div>
                                                        <br />
                                                        <div className="m-0" style={{ marginTop: '7px', display: 'flex', flexWrap: 'wrap' }} >
                                                            <div style={{ margin: '2px' }}>
                                                                <Tag value={selectedPost.category.name} icon="pi pi-microsoft" rounded />
                                                            </div>
                                                            <div style={{ margin: '2px' }}>
                                                                <Tag value={selectedPost.jobType.name} icon="pi pi-briefcase" rounded />
                                                            </div>
                                                            <div style={{ margin: '2px' }}>
                                                                {selectedPost.jobLocation === null ? 'USA' : <Tag value={selectedPost.jobLocation} icon={<img src={locationImage} alt='' />} rounded />}
                                                            </div>
                                                            <div style={{ margin: '2px' }}>
                                                                {
                                                                    selectedPost.jobPosition === "100% Remote(Anywhere in the world)" ? <Tag value={post.jobPosition} icon="pi pi-globe" rounded /> : ''
                                                                }
                                                                {
                                                                    selectedPost.jobPosition === "100% Remote (Restricted to a specific geography/location)" ? <Tag value="100% Remote" icon="pi pi-wifi" rounded /> : ''
                                                                }

                                                                {
                                                                    selectedPost.jobPosition === "Remote Hybrid" ? <Tag value={selectedPost.jobPosition} icon="pi pi-slack" rounded /> : ''
                                                                }
                                                                {
                                                                    selectedPost.jobPosition === "In-Office" ? <Tag value={selectedPost.jobPosition} icon="pi pi-building" rounded /> : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <p>Tags</p>
                                                        <div style={{ marginTop: '9px', display: 'flex', flexWrap: 'wrap' }}>
                                                            {selectedPost.chipArray.map((chip) => {
                                                                return (
                                                                    <>
                                                                        <Tag value={chip} style={{ marginRight: '2px', backgroundColor: 'white', color: '#6366F1', border: '0.5px solid #6366F1' }}>
                                                                        </Tag>
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </Card>
                                                </div>
                                            </div>
                                        </TabPanel>
                                    </TabView>
                                    <div style={{position: 'fixed', bottom: '0',marginLeft: '-25px' }}>
                                        <Button className='applyButton' label='Apply' icon="pi pi-external-link"  onClick={() => openInNewTab(selectedPost.applyURL)} />
                                    </div>
                                </Dialog>
                            )}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Admin
