import React from 'react'
 
function Company() {
  return (
    <div >
        <h2>Companies</h2>
    </div>
  )
}

export default Company