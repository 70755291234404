import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";

function Footer() {
  const [firstName, setFirstName] = useState(""); // State for first name
  const [email, setEmail] = useState(""); // State for email
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  const showSuccessToast = () => {
    toast.current.show({
      severity: "success",
      summary: "Subscription successful!",
    });
  };

  const showErrorToast = (message) => {
    toast.current.show({
      severity: "error",
      summary: message,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!firstName || !email || !validateEmail(email)) {
      showErrorToast("Please enter a valid first name and email address.");
      return;
    }

    setLoading(true);

    try {
      // Add the email and first name to the 'signups' collection in Firestore
      await addDoc(collection(db, "signups"), {
        firstName, // Store first name
        email, // Store email
        timestamp: new Date(),
      });

      showSuccessToast(); // Show success toast
      setFirstName(""); // Clear first name input
      setEmail(""); // Clear email input
    } catch (error) {
      console.error("Error submitting data: ", error);
      showErrorToast("There was an error submitting your details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "12rem",
          backgroundColor: "#01192d",
          bottom: "0",
          zIndex: "9",
          position: "sticky",
        }}
      >
        <div
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{textAlign: 'center'}}>
            <h3>Subscribe to get job alerts directly to your inbox</h3>
          </div>

          <div id="PostData" className="flex gap-2 sm:flex text-xs" style={{alignItems: 'center'}}>
            <Toast ref={toast} position="bottom-right" />

            <span className="p-float-label">
              <InputText
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)} // Update first name state
                placeholder="First Name"
                className="hidden sm:block"
              />
              <label>First Name</label>
            </span>

            <span className="p-float-label">
              <InputText
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email state
                placeholder="Enter your email"
              />
              <label>Email</label>
            </span>

            <Button
              label={loading ? "Subscribing..." : "Subscribe"}
              className="block  border-round-3xl w-full md:block md:mt-0 md:w-auto"
              onClick={handleSubmit}
              disabled={loading}
              type="submit"
              id="formSubmitBtn"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5 style={{ color: "white", marginLeft: "4px" }}>
            © 2023 CryptoGater | Legal
          </h5>
          <a
            href="/post-a-job"
            target="blank"
            style={{ textDecoration: "none" }}
            outlined
          >
            <Button
              className="hidden lg:flex"
              label="Post a Job"
              icon="pi pi-arrow-right"
              iconPos="right"
              outlined
              style={{ marginRight: "65px" }}
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
