// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "cryptogator-ac09a.firebaseapp.com",
  databaseURL: "https://cryptogator-ac09a-default-rtdb.firebaseio.com",
  projectId: "cryptogator-ac09a",
  storageBucket: "cryptogator-ac09a.appspot.com",
  messagingSenderId: "18522126644",
  appId: "1:18522126644:web:a113abd6eed9d6b1c54d61",
  measurementId: "G-K9FT4P4G6T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  });
export const db = getFirestore(app);

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);


//const analytics = getAnalytics(app);