import React from 'react'
import Development from '../../components/Development'

const Development_jobs = () => {
  return (
    <div>
        { <Development /> }
    </div>
  )
}

export default Development_jobs
