import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const EmailerHeader = () => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  const showSuccessToast = () => {
    toast.current.show({
      severity: "success",
      summary: "Email submitted successfully!",
      position: "top-center",
      style: { zIndex: 9999 }
    });
  };

  const showErrorToast = (message) => {
    toast.current.show({
      severity: "error",
      summary: message,
      position: "top-center",
      style: { zIndex: 9999 }
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!value || !validateEmail(value)) {
      // Alert the user if the email is invalid
      showErrorToast("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    try {
      // Add the email to the 'signups' collection in Firestore
      await addDoc(collection(db, "signups"), {
        email: value, // Only the email is stored
        timestamp: new Date(), // Optional: Adding a timestamp
      });

      showSuccessToast(); // Show success toast
      setValue(''); // Clear the input after submission
    } catch (error) {
      console.error("Error submitting email: ", error);
      showErrorToast("There was an error submitting your email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Toast ref={toast} />

      <div className="flex flex-column md:flex-row">
        <InputText
          value={value}
          onChange={(e) => setValue(e.target.value)}
          keyfilter="email"
          placeholder="Enter your email"
          className="border-round-3xl w-full md:w-full"
          style={{ marginRight: '-3rem' }}
        />
        <Button 
          label={loading ? "Submitting..." : "Join Now"} 
          className="block mt-2 border-round-3xl w-full md:block md:mt-0 md:w-auto"
          onClick={handleSubmit} 
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default EmailerHeader;
