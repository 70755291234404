import React from 'react'
import Fulltime from '../../components/Fulltime'

const Fulltime_jobs = () => {
  return (
    <div>
        { <Fulltime /> }
    </div>
  )
}

export default Fulltime_jobs
