import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Chips } from "primereact/chips";
import { Editor } from "primereact/editor";
import { InputNumber } from "primereact/inputnumber";
import { Divider } from "primereact/divider";
import { serverTimestamp, addDoc, collection } from "firebase/firestore";
import { db, storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { RadioButton } from "primereact/radiobutton";
import "../pages/PostAJob.css";
import Footer from "../components/footer/footer";
import { useNavigate } from "react-router-dom";
import Features from "../components/Features";
import { MultiSelect } from "primereact/multiselect";
//import Stripe from "../components/Stripe";
//import stripeButton from "../components/Stripe";
import TooltipComponent from "../components/Tooltip";


function PostAJob() {
  const navigate = useNavigate();
  const [perc, setPerc] = useState(null);

  const toast = useRef(null);
  const show = () => {
    toast.current.show({
      severity: "success",
      summary: "Your job post is submitted successfully",
      detail: getValues("value"),
      position: "top-center",
    });
  };

  const mandatoryFieldsWarning = () => {
    toast.current.show({
      severity: "warn",
      summary: "Please fill in the mandatory fields",
      detail: "Message Content",
      life: 3000,
    });
  };

  const defaultValues = {
    jobTitle: "",
    category: "",
    minExperience: "",
    maxExperience: "",
    jobType: "",
    chipArray: null,
    isRemote: false,
    jobLocation: "",
    jobDescription: "",
    currency: "",
    minSalary: "",
    maxSalary: "",
    applyURL: "",
    benefitsOffered: null,
    companyName: "",
    companyTagline: "",
    companyWebsite: "",
    companyDescription: "",
    twitterHandle: "",
    employerName: "",
    companyEmail: "",
    jobPosition: null,
    img: null,
    isActive: "false",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({ defaultValues });

  //GoogleAppScript URL
  const WebAppURL = "";

  //Date & Month of the job posting
  const timestamp = new Date();
  const suffix = (day) =>
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";
  const formattedDate = `${timestamp.getDate()}${suffix(
    timestamp.getDate()
  )} ${timestamp.toLocaleDateString("en-US", { month: "short" })}`;

  // Adding 30 days to the current timestamp
  const dateOfExpiry = new Date(timestamp.getTime() + 30 * 24 * 60 * 60 * 1000);


  const onSubmit = async (data, reset) => {
    data.value && show();
    data.category && show(data);
    data.chipArray.length > 0 && show(data);

    await addDoc(collection(db, "posts"), {
      ...data,
      timeStamp: serverTimestamp(),
      timeStampUTC: new Date().toISOString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      postedDate: formattedDate,
      expiryDate: dateOfExpiry,
    });

    //Dropdown values
    const dropDownCategory = [data.category.name];
    const dropDownJobType = [data.jobType.name];
    const dropDownCurrency = [data.currency.name];

    //Appending additional data i.e category, type, currency to the data obj
    data.jobCategory = dropDownCategory;
    data.jobType = dropDownJobType;
    data.jobCurrency = dropDownCurrency;

    // Post job data to google sheets
    await fetch(WebAppURL, {
      method: "POST",
      mode: "no-cors", // Add no-cors mode
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(data),
    });

    //reset();
    setTimeout(() => {
      navigate("/Success");
    }, 2000);
  };

  const [file, setFile] = useState("");
  const [imgfile, imgsetFile] = useState();

  useEffect(() => {
    const uploadFile = () => {
      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              toast.current.show({
                severity: "error",
                summary:
                  "This logo cannot be uploaded. Please upload another image",
                position: "top-center",
              });
              console.log("Upload is paused");
              break;
            case "running":
              toast.current.show({
                severity: "success",
                summary: "Logo has been uploaded successfully",
                position: "top-center",
              });
              console.log("Upload is running");
              break;
              default:
              console.log("Unknown upload state");
              break;
          }
        },
        (error) => {},
        async () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            addDoc((data) => ({ ...data, img: downloadURL }));
            console.log("File available at", downloadURL);
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleChange = async (e) => {
    console.log(e.target.files);
    imgsetFile(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const categories = [
    { name: "Development", code: "dev" },
    { name: "Design", code: "design" },
    { name: "Data", code: "data" },
    { name: "Sales", code: "sales" },
    { name: "Marketing", code: "marketing" },
    { name: "Media", code: "media" },
    { name: "Product", code: "prod" },
    { name: "Operations", code: "op" },
    { name: "QA/Automation", code: "qa" },
    { name: "Business Development", code: "bd" },
    { name: "Customer Support", code: "cs" },
    { name: "Finance & Management", code: "fm" },
    { name: "Information Security", code: "is" },
    { name: "DevOps & SysAdmin", code: "devops" },
    { name: "Non-Tech", code: "nt" },
    { name: "Others", code: "others" },
  ];

  const jobTypes = [
    { name: "Full-Time", code: "full-time" },
    { name: "Part-Time", code: "part-time" },
    { name: "Contract", code: "contract" },
    { name: "Freelance", code: "freelance" },
    { name: "Internship", code: "internship" },
    { name: "Volunteer", code: "volunteer" },
  ];

  const currencies = [
    { name: "USD", code: "$" },
    { name: "AUD", code: "$" },
    { name: "EUR", code: "€" },
    { name: "GBP", code: "£" },
    { name: "CAD", code: "$" },
    { name: "INR", code: "₹" },
    { name: "JPY", code: "¥" },
    { name: "CNY", code: "¥" },
    { name: "NZD", code: "$" },
    { name: "CHF", code: "₣" },
    { name: "SEK", code: "₺" },
  ];

  const [visible, setVisible] = useState(false);

  // const [selectedBenefits, setSelectedBenefits] = useState(null);

  const benefits = [
    { name: "4 day work week" },
    { name: "401(k) plan" },
    { name: "Flexible working hours" },
    { name: "Work from home" },
    { name: "Pay in Crypto" },
    { name: "Company retreats" },
    { name: "Coworking budget" },
    { name: "Medical Insurance" },
    { name: "Dental insurance" },
    { name: "Travel insurance" },
    { name: "Vision Insurance" },
    { name: "Distributed team" },
    { name: "Equity compensation" },
    { name: "Unlimited Vacation" },
    { name: "Pet friendly workplace" },
    { name: "Flexible Spending Account(FSA)" },
    { name: "Health savings account(HSA)" },
    { name: "Free gym membership" },
    { name: "Onsite Gym" },
    { name: "Home office budget" },
    { name: "Learning & development budget" },
    { name: "Mental wellness budget" },
    { name: "Pension Matching" },
  ];

  // Form Submit button - Onclick function

  // const onClick = async(e) => {

  //     //Save data to db
  //     setFile(e.target.files[0])
  //     //opens dialog and calls Stripe function
  //     setVisible(true);
  // }

  return (
    <>
      <Features />
      <div style={{ padding: "2% 18%", margin: "50px 0 70px 0" }}>
        <form
          id="PostData"
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-column gap-2"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <h2>Tell me about the job details</h2>
          <Toast ref={toast} />
          {/* Job Title    */}

          <h4 style={{ marginBottom: "10px" }}>
            Job Title <span style={{marginLeft: '7px'}}><TooltipComponent description="Please enter only one job title unique to a specific job role"/></span>
          </h4>
          <Controller
            name="jobTitle"
            control={control}
            rules={{ required: "Job title is required." }}
            render={({ field, fieldState }) => (
              <>
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                ></label>
                <span className="p-float-label">
                  <InputText
                    id="jobtitle"
                    inputId={field.name}
                    class="w-full md:w-50rem"
                    value={field.value}
                    placeholder="Eg : Blockchain developer, Crypto Analyst, dApps developer "
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {/* <label htmlFor={field.name}>Job title</label> */}
                </span>
                {getFormErrorMessage(field.name)}
              </>
            )}
          />
          

          {/* Job Category */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4 style={{ marginBottom: "10px" }}>Select a Category</h4>
            <Controller
              name="category"
              control={control}
              rules={{ required: "Job Category is required." }}
              render={({ field, fieldState }) => (
                <Dropdown
                  id="jobcategory"
                  value={field.value}
                  optionLabel="name"
                  placeholder="Select a Category"
                  name="category"
                  options={categories}
                  control={control}
                  onChange={(e) => field.onChange(e.value)}
                  className={classNames({ "p-invalid": fieldState.error })}
                  style={{ width: "50%" }}
                />
              )}
            />
            {getFormErrorMessage("category")}
          </div>

          <br/>

          {/* Job Experience  */}
          <label htmlFor="jobExperience">
            <h4>
              Job Experience <br />
              What is the years of job experience you are looking for?
              <span style={{marginLeft: '7px'}}><TooltipComponent description="0-2 years: For entry-level positions or internships.
3-5 years: For mid-level roles requiring moderate experience.
5+ years: For senior or expert-level roles with significant experience." /></span>
            </h4>
          </label>
          <div className="flex flex-column md:flex md:flex-row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "2rem",
              }}
            >
              <Controller
                name="minExperience"
                control={control}
                rules={{
                  validate: (value) =>
                    value >= 0 ||
                    null ||
                    "Enter a valid number for years of experience",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name}>Minimum(in yrs)</label>
                    <InputNumber
                      id={field.name}
                      className="p-inputtext-sm"
                      suffix="yrs"
                      inputRef={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      useGrouping={false}
                      inputClassName={classNames({
                        "p-invalid": fieldState.error,
                      })}
                    />
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Controller
                name="maxExperience"
                control={control}
                rules={{
                  validate: (value) =>
                    value >= 0 ||
                    null ||
                    "Enter a valid number for years of experience",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name}>Maximum(in yrs)</label>
                    <InputNumber
                      id={field.name}
                      className="p-inputtext-sm"
                      suffix="yrs"
                      inputRef={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      useGrouping={false}
                      inputClassName={classNames({
                        "p-invalid": fieldState.error,
                      })}
                    />
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
          </div>

          <br/>

          {/* Skills or Tags */}
          <h4>
            Skills <br/>          
            <span style={{fontSize: '12px'}}>USE COMMA ( , ) OR PRESS ENTER TO ADD MULTIPLE TAGS</span>
          </h4>
          <Controller
            name="chipArray"
            control={control}
            // rules={{ required: 'At least one chip is required.' }}
            render={({ field, fieldState }) => (
              <Chips
                id={field.name}
                name="chipArray"
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ "p-invalid": fieldState.error })}
                separator=","
              />
            )}
          />
          <span style={{fontSize: '11.2px'}}>
            Short tags are preferred.You could list tags that are in relevance
            with your skills preference,tech stack and industry.
            <br />
            Eg: DeFi, NFT's, Solidity, Healthcare, Finance, etc
          </span>
          {getFormErrorMessage("chipArray")}

          <br/>

          {/* Type of Position */}
          <h4 style={{ marginBottom: "10px" }}>Type of Position</h4>
          <Controller
            name="jobType"
            control={control}
            rules={{ required: "Type of Position is required." }}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel="name"
                placeholder="Type of Position"
                name="JobType"
                options={jobTypes}
                control={control}
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ "p-invalid": fieldState.error })}
                style={{ width: "50%" }}
              />
            )}
          />
          {getFormErrorMessage("JobType")}

          <br/>

          <h4 style={{ marginBottom: "10px" }}>
            Work Arrangement <br />
            Is the job fully remote, hybrid or on-site?
          </h4>
          <Controller
            name="jobPosition"
            control={control}
            rules={{ required: "Value is required." }}
            render={({ field }) => (
              <>
                <div
                  className="flex"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div style={{ marginBottom: "5px" }}>
                    <RadioButton
                      inputId="f5"
                      {...field}
                      inputRef={field.ref}
                      name="radio"
                      value="100% Remote(Worldwide)"
                      checked={
                        field.value === "100% Remote(Worldwide)"
                      }
                    />
                    <label htmlFor="f5" style={{ marginLeft: "7px" }}>
                      100% Remote (Worldwide)
                    </label>
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <RadioButton
                      inputId="f5"
                      {...field}
                      inputRef={field.ref}
                      name="radio"
                      value="100% Remote(Location-restricted)"
                      checked={field.value === "100% Remote(Location-restricted)"}
                    />
                    <label htmlFor="f5" style={{ marginLeft: "7px" }}>
                      100% Remote (Restricted to a specific geography/location)
                    </label>
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <RadioButton
                      inputId="f6"
                      {...field}
                      value="Remote Hybrid"
                      name="radio"
                      checked={field.value === "Remote Hybrid"}
                    />
                    <label htmlFor="f6" style={{ marginLeft: "7px" }}>
                      Remote Hybrid
                    </label>
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    <RadioButton
                      inputId="f6"
                      {...field}
                      value="On-Site"
                      name="radio"
                      checked={field.value === "On-Site"}
                    />
                    <label htmlFor="f6" style={{ marginLeft: "7px" }}>
                      On-Site
                    </label>
                  </div>
                </div>

                <br/>

                {field.value !== "100% Remote(Worldwide)" && (
                  <div style={{ marginTop: "2rem" }}>
                    <h4 style={{ marginBottom: "-14px" }}>
                      Where is the job location restricted to?
                    </h4>
                    <h6>
                      Eg: Newyork(city)/Florida(state)/USA(country)/Asia
                      only(continent)
                    </h6>
                    <Controller
                      name="jobLocation"
                      control={control}
                      rules={{
                        required: "Please enter a city/state/country/continent",
                      }}
                      render={({ field, fieldState }) => (
                        <>
                          <label
                            htmlFor={field.name}
                            className={classNames({ "p-error": errors.value })}
                          ></label>
                          <span className="p-float-label">
                            <InputText
                              inputId={field.name}
                              style={{ width: "50%" }}
                              value={field.value}
                              placeholder="Enter a city/country/continent"
                              inputRef={field.ref}
                              className={classNames({
                                "p-invalid": fieldState.error,
                              })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />
                            {/* <label htmlFor={field.name}>Job Location</label> */}
                          </span>
                          {getFormErrorMessage(field.name)}
                        </>
                      )}
                    />
                  </div>
                )}
                {getFormErrorMessage(field.name)}
              </>
            )}
          />

          {/* Job Location */}

          {/* Job Description */}
          <label htmlFor="jobDescription">
            <h4 style={{ marginBottom: "-12px" }}>Job Description</h4>
          </label>
          <h6>
            (Dont worry about the formatting. We will take care it for you)
          </h6>
          <Controller
            name="jobDescription"
            control={control}
            rules={{ required: "Please enter some details about the job" }}
            render={({ field }) => (
              <Editor
                id={field.name}
                name="jobDescription"
                value={field.value}
                onTextChange={(e) => field.onChange(e.textValue)}
                style={{ height: "320px" }}
              />
            )}
          />
          {getFormErrorMessage("jobDescription")}

          <br/>

          {/* Currency */}
          <label htmlFor="currency">
            <h4>Job Compensation</h4>
          </label>
          <Controller
            name="currency"
            control={control}
            render={({ field, fieldState }) => (
              <Dropdown
                value={field.value}
                optionLabel="name"
                placeholder="Currency"
                name="currency"
                options={currencies}
                control={control}
                filter
                filterBy="name"
                onChange={(e) => field.onChange(e.value)}
                className={classNames({ "p-invalid": fieldState.error })}
                style={{ width: "50%" }}
              />
            )}
          />
          {getFormErrorMessage("currency")}

          {/* Job Compensation  */}

          <label htmlFor="salaryRange">
            <h4>Enter a salary range</h4>
          </label>
          <div className="flex flex-column md:flex md:flex-row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "2rem",
              }}
            >
              <Controller
                name="minSalary"
                control={control}
                rules={{
                  validate: (value) =>
                    value >= 0 || null || "Enter a valid salary range",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name}>Minimum salary</label>
                    <InputNumber
                      id={field.name}
                      inputRef={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      useGrouping={false}
                      inputClassName={classNames({
                        "p-invalid": fieldState.error,
                      })}
                      style={{ width: "20%", marginTop: '5px' }}
                    />
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Controller
                name="maxSalary"
                control={control}
                rules={{
                  validate: (value) =>
                    value >= 0 || null || "Enter a valid salary range",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <label htmlFor={field.name}>Maximum salary</label>
                    <InputNumber
                      id={field.name}
                      inputRef={field.ref}
                      value={field.value}
                      onBlur={field.onBlur}
                      onValueChange={(e) => field.onChange(e)}
                      useGrouping={false}
                      inputClassName={classNames({
                        "p-invalid": fieldState.error,
                      })}
                      style={{ width: "20%", marginTop: '5px'}}
                    />
                    {getFormErrorMessage(field.name)}
                  </>
                )}
              />
            </div>
          </div>

          <br />

          {/* Apply URL/Email */}
          <Controller
            name="applyURL"
            control={control}
            rules={{ required: "Website URL or Email is required" }}
            render={({ field, fieldState }) => (
              <>
                <h4
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  How to apply?
                </h4>
                <span className="p-float-label">
                  <InputText
                    inputId={field.name}
                    value={field.value}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="mailto:apply@company.com OR https://company.com/apply"
                    style={{ width: "50%" }}
                  />
                  <label htmlFor={field.name}>
                    Apply through website / email
                  </label>
                </span>
                {getFormErrorMessage(field.name)}
              </>
            )}
          />
          <h6 style={{ marginTop: "0" }}>
            NOTE : Specify <i>mailto:hr@company.com</i> when using an email to
            receive applications. <br />
            'mailto' keyword before your EmailID is of high importance.
          </h6>

          <br />

          <Controller
            name="benefitsOffered"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <h4
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  What are the benefits offered?
                </h4>
                <span className="p-float-label">
                  <MultiSelect
                    id="benefits"
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={benefits}
                    control={control}
                    optionLabel="name"
                    display="chip"
                    placeholder="Select Benefits"
                    maxSelectedLabels={3}
                    className={classNames("w-full md:w-30rem", {
                      "p-invalid": fieldState.error,
                    })}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </>
            )}
          />

          <br />
          <Divider />
          <br />

          <h3>Company Details</h3>

          {/* Company Logo */}
          <label>Upload your Company Logo</label>
          <br />
          <label
            htmlFor="file"
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <div
              className="card flex justify-content-center"
              style={{
                width: "10rem",
                height: "10rem",
                borderRadius: "50%",
                border: "1px solid black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <img
                src={imgfile}
                style={{ width: "auto", height: "auto", borderRadius: "50%" }}
                alt=""
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "9px",
              }}
            >
              <p style={{ marginRight: "5px" }}>Upload</p>
              <i className="pi pi-upload" style={{ fontSize: "1rem" }}>
                <input
                  type="file"
                  onChange={handleChange}
                  id="file"
                  style={{ display: "none" }}
                />
              </i>
            </div>
          </label>

          <br />

          {/* Company Name */}
          <div style={{ marginTop: "25px" }}>
            <Controller
              name="companyName"
              control={control}
              rules={{ required: "Please enter a company name." }}
              render={({ field, fieldState }) => (
                <>
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  ></label>
                  <span className="p-float-label">
                    <InputText
                      inputId={field.name}
                      value={field.value}
                      inputRef={field.ref}
                      className={classNames({ "p-invalid": fieldState.error })}
                      onChange={(e) => field.onChange(e.target.value)}
                      style={{ width: "50%" }}
                    />
                    <label htmlFor={field.name}>Company Name</label>
                  </span>
                  {getFormErrorMessage(field.name)}
                </>
              )}
            />
          </div>

          <br />

          {/* Company Tagline */}
          <Controller
            name="companyTagline"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                ></label>
                <span className="p-float-label">
                  <InputTextarea
                    inputId={field.name}
                    value={field.value}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                    style={{ width: "50%" }}
                  />
                  <label htmlFor={field.name}>Company Tagline</label>
                </span>
                {getFormErrorMessage(field.name)}
              </>
            )}
          />

          <br />

          {/* Company Website URL */}
          <Controller
            name="companyWebsite"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                ></label>
                <span className="p-float-label">
                  <InputText
                    inputId={field.name}
                    value={field.value}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="e.g https://company.com"
                    style={{ width: "50%" }}
                  />
                  <label htmlFor={field.name}>Company Website</label>
                </span>
                {getFormErrorMessage(field.name)}
              </>
            )}
          />

          <br />
          <br />
          <br />
          <br />

          <label htmlFor="companyDescription">About the Company</label>
          <Controller
            name="companyDescription"
            control={control}
            render={({ field }) => (
              <Editor
                id={field.name}
                name="companyDescription"
                value={field.value}
                onTextChange={(e) => field.onChange(e.textValue)}
                placeholder="Describe about your company such as what makes your company special, your challenges, the products/services that you have built or are building, the culture at workspace, how much funding have you secured ,etc"
                style={{ height: "320px" }}
              />
            )}
          />

          {getFormErrorMessage("companyDescription")}

          <br />

          {/* Social Media handles */}
          <label>Twitter Handle</label>
          <Controller
            name="twitterHandle"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                ></label>
                <div className="p-inputgroup flex-1 w-full md:w-30rem">
                  <span className="p-inputgroup-addon">
                    <i>@</i>
                  </span>
                  <InputText
                    inputId={field.name}
                    value={field.value}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Twitter handle"
                    keyfilter="alphanum"
                  />
                </div>
                {getFormErrorMessage(field.name)}
              </>
            )}
          />

          <br />

          <Divider />

          <h3>Contact Information</h3>
          <br />
          <Controller
            name="employerName"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                ></label>
                <span className="p-float-label">
                  <InputText
                    inputId={field.name}
                    value={field.value}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                    style={{ width: "50%" }}
                    placeholder="Samantha Browne"
                  />
                  <label htmlFor={field.name}>Name</label>
                </span>
                {getFormErrorMessage(field.name)}
              </>
            )}
          />

          <br />

          <Controller
            name="companyEmail"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                ></label>
                <span className="p-float-label">
                  <InputText
                    inputId={field.name}
                    value={field.value}
                    inputRef={field.ref}
                    className={classNames({ "p-invalid": fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                    style={{ width: "50%" }}
                    placeholder="hr@company.com"
                  />
                  <label htmlFor={field.name}>Business Email</label>
                </span>
                {getFormErrorMessage(field.name)}
                <h6>
                  For invoices, edit links and further business communications
                </h6>
              </>
            )}
          />

          <br />
          <br />
          <br />

          <Button
            label="Submit"
            type="submit"
            disabled={perc !== null && perc < 100}
            id="formSubmitBtn"
          />

               {/* Modal popup for stripe payment displaying the features of cryptogater */}
               {/* <Dialog
            header="Payment"
            visible={visible}
            maximizable
            style={{ width: "30vw" }}
            onHide={() => setVisible(false)}
          >
            <div className="p-3 h-full">
              <div
                className="shadow-2 p-3 h-full flex flex-column"
                style={{ borderRadius: "6px" }}
              >
                <div className="text-900 font-medium text-xl mb-2">
                  Single job post
                </div>
                <div className="text-600">Plan description</div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <div className="flex align-items-center">
                  <span className="font-bold text-2xl text-900">$299</span>
                  <span className="ml-2 font-medium text-600">per post</span>
                </div>
                <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                <ul className="list-none p-0 m-0 flex-grow-1">
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>30 days</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Multiple Social Media Posts</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Mobile Responsive Layout</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>Google Job Indexing</span>
                  </li>
                  <li className="flex align-items-center mb-3">
                    <i className="pi pi-check-circle text-green-500 mr-2"></i>
                    <span>
                      Backlink your job post in handpicked crypto communities
                    </span>
                  </li>
                </ul>
                <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                <div className="flex justify-content-center"></div>
              </div>
            </div>
               </Dialog> */}
        </form>
       </div>
       <div className="Footer" style={{ marginBottom: "0px" }}>
         <Footer />
       </div>
     </>
  );
}

export default PostAJob